import { Button, Flex, Grid, Skeleton } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { NoDataPlaceholder } from 'components/common/table/no-data-placeholder';
import { GameDataGameCard } from 'components/sections/game-data/games/card';
import { GameDataContext } from 'contexts/game-data.context';
import { t } from 'i18next';
import { isAppearanceDark } from 'index';
import { ITableNoData } from 'interfaces/tables/no-data';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo, useState } from 'react';
import { GameDataGameFilters } from './filters';

const COMPONENT_NAME = 'GameDataGames';

const ITEMS_PER_PAGE = 100;

export const GameDataGames = () => {
  const [pages, setPages] = useState(1);

  const { loading, seasonGames, filterGames } = useContext(GameDataContext);

  const games = useMemo(() => {
    return seasonGames

      .filter(
        (g) =>
          filterGames.teamPk === undefined ||
          [g.home.teamPk, g.away.teamPk].includes(filterGames.teamPk)
      )
      .filter(
        (g) =>
          filterGames.date === undefined ||
          g.officialDate.startsWith(filterGames.date)
      );
  }, [seasonGames, filterGames]);

  const isDark = isAppearanceDark();

  const noDataProps: ITableNoData = {
    noDataHeader: 'No Games to Display',
    noDataBody: 'No games match your search',
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="3">
        <GameDataGameFilters />

        <Skeleton loading={loading}>
          {games.length === 0 && <NoDataPlaceholder {...noDataProps} />}

          <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="3">
            {games
              .filter((_, i) => i < pages * ITEMS_PER_PAGE)
              .map((g) => (
                <GameDataGameCard key={g.gamePk} isDark={isDark} game={g} />
              ))}

            {games.length > pages * ITEMS_PER_PAGE && (
              <Flex
                justify="center"
                gridColumn={{ initial: undefined, md: 'span 2', lg: 'span 3' }}
              >
                <Button
                  onClick={() => setPages(pages + 1)}
                  variant={RADIX.BUTTON.VARIANT.SECONDARY}
                >
                  {t('common.load-more')}
                </Button>
              </Flex>
            )}
          </Grid>
        </Skeleton>
      </Flex>
    </ErrorBoundary>
  );
};
