import { LoopIcon, ShuffleIcon } from '@radix-ui/react-icons';
import { CustomIcon } from 'components/common/custom-icon';
import { TableContext } from 'components/common/table/context';
import { AimingContext } from 'contexts/aiming.context';
import { GlobalContext } from 'contexts/global.context';
import { HittersContext } from 'contexts/hitters.context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { CustomIconPath } from 'enums/custom.enums';
import { ResetPlateMode } from 'enums/machine.enums';
import {
  IQueueDefinition,
  IQueueOptions,
  QueueType,
} from 'interfaces/i-queue-mode';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { SHUFFLE_FREQUENCY_OPTIONS } from 'lib_ts/enums/pitches.enums';
import { IPitch } from 'lib_ts/interfaces/pitches';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const CONTEXT_NAME = 'QueueContext';

const MIN_SHUFFLE_FREQUENCY = parseInt(SHUFFLE_FREQUENCY_OPTIONS[0].value);
const USE_BUCKET_LOTTERY = true;

const REPEAT_ONE: IQueueDefinition = {
  type: QueueType.RepeatOne,
  label: 'Repeat One',
  tooltip: 'Repeat a pitch until a new one is selected.',
  icon: <CustomIcon icon={CustomIconPath.RepeatOne} />,
};

const REPEAT_ALL: IQueueDefinition = {
  type: QueueType.RepeatAll,
  label: 'Repeat All',
  tooltip: 'Repeat all pitches in list according to the current sequence.',
  icon: <LoopIcon />,
};

const SHUFFLE_EACH: IQueueDefinition = {
  type: QueueType.ShuffleEach,
  label: 'Shuffle',
  tooltip: 'Select a random next pitch after each fire.',
  icon: <ShuffleIcon />,
};

export const Q_DEFINITIONS: IQueueDefinition[] = [
  REPEAT_ALL,
  REPEAT_ONE,
  SHUFFLE_EACH,
];

interface IProps {
  pitches: IPitch[];
  children: ReactNode;
}

export interface IQueueContext {
  // for signalling to fire button to reset its own awaiting timeout
  // +ve => await resend, -ve => don't wait
  resendKey: number;

  def: IQueueDefinition;
  ids: string[];

  autoSendID: string | undefined;
  // ! consider deprecating because nothing outside of this context needs to do this
  readonly resetAutoSendID: (value: string) => void;

  fromRemote: boolean;
  readonly setFromRemote: (value: boolean) => void;

  readonly changeQueue: (type: QueueType, options?: IQueueOptions) => void;
  readonly changePitch: (config: {
    delta: number;
    delay_ms?: number;
    autoSend?: boolean;
  }) => void;

  readonly sendSelected: (trigger: string, auto: boolean) => void;
}

const DEFAULT: IQueueContext = {
  resendKey: -Date.now(),

  def: REPEAT_ONE,
  ids: [],

  autoSendID: undefined,
  resetAutoSendID: () => console.error(`${CONTEXT_NAME}: not init`),

  fromRemote: false,
  setFromRemote: () => console.error(`${CONTEXT_NAME}: not init`),

  changeQueue: () => console.error(`${CONTEXT_NAME}: not init`),
  changePitch: () => console.error(`${CONTEXT_NAME}: not init`),

  sendSelected: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const QueueContext = createContext(DEFAULT);

export const QueueProvider: FC<IProps> = (props) => {
  const sendTimeout = useRef<NodeJS.Timeout>();

  const { dialogs } = useContext(GlobalContext);
  const { checkActive, resetMSHash, sendPitchPreview } =
    useContext(MachineContext);
  const { lists } = useContext(PitchListsContext);
  const { active: aHitter } = useContext(HittersContext);
  const { pitch, setPitch } = useContext(AimingContext);
  const { selectedData, sortedData, lookupCoordinates, setSelected } =
    useContext(TableContext);
  const { getChecked } = useContext(CheckedContext);
  const { isPitchTrained, fetchShotsForBP } = useContext(MatchingShotsContext);

  const [lastResend, setLastResend] = useState(DEFAULT.resendKey);
  const [def, setDef] = useState(DEFAULT.def);
  const [pitchIDs, setPitchIDs] = useState<string[]>(DEFAULT.ids);

  const [autoSendID, setAutoSendID] = useState(DEFAULT.autoSendID);
  const [autoSendDelayMS, setAutoSendDelayMS] = useState<number | undefined>();
  const [fromRemote, setFromRemote] = useState(DEFAULT.fromRemote);

  const getNextQueue = useCallback(
    (options: IQueueOptions | undefined) => {
      const trained = sortedData.filter((p) => isPitchTrained(p));

      const trainedAndChecked = trained
        .filter((p) => getChecked(p._id))
        .map((p) => p._id);

      if (!options && trainedAndChecked.length === 0) {
        // nothing is checked => queue is everything that is trained
        return trained.map((p) => p._id);
      }

      // at least one pitch is checked => queue should only contain trained AND checked pitches
      const nextQueue = pitchIDs.filter((id) => {
        const pitch = sortedData.find((p) => p._id === id);

        if (!pitch) {
          // cannot find the pitch in the active list
          return false;
        }

        if (!trainedAndChecked.includes(id)) {
          // exclude previously queued pitch because it's not checked
          return false;
        }

        if (
          options &&
          options.action === 'remove' &&
          pitch._id === options.id
        ) {
          // apply remove option
          return false;
        }

        // anything that will continue to be queued, leave intact
        return true;
      });

      // add new items to the end (e.g. something became trained or checked)
      trainedAndChecked.forEach((id) => {
        if (!nextQueue.includes(id)) {
          nextQueue.push(id);
        }
      });

      if (
        options &&
        options.action === 'add' &&
        !nextQueue.includes(options.id) &&
        !!trained.find((p) => p._id === options.id)
      ) {
        // apply add option
        nextQueue.push(options.id);
      }

      return nextQueue;
    },
    [pitchIDs, sortedData, isPitchTrained, getChecked]
  );

  const getNext = useCallback(
    (delta: number) => {
      switch (def.type) {
        case QueueType.ShuffleEach: {
          if (USE_BUCKET_LOTTERY) {
            // this algo picks a frequency bucket first and then picks a matching pitch from the bucket
            // e.g. with frequencies 1, 2, and 3 in use, the pool of values will be [1, 2, 2, 3, 3, 3]
            // it will pick a random value from this pool (the bucket), and then find all pitches with matching frequency value (items in the bucket)
            // then it will pick a random pitch from the matches
            // this ensures that have many pitches of the same frequency doesn't dilute all other frequencies
            const qPitches = pitchIDs
              .map((id) => props.pitches.find((p) => p._id === id))
              .filter((p) => p) as IPitch[];

            // get unique frequencies that occur in the queue
            const qFreqs: number[] = ArrayHelper.unique(
              qPitches.map((p) => p.frequency ?? MIN_SHUFFLE_FREQUENCY)
            );

            // weighted freq => higher numbers will show up more times
            const wFreqs: number[] = qFreqs.flatMap((f) => {
              const o: number[] = [];

              // e.g. 3 => 3 will show up 3 times
              for (let i = 0; i < f; i++) {
                o.push(f);
              }

              return o;
            });

            // pick one of the freq randomly
            const rFreq =
              wFreqs[Math.round(Math.random() * 1_000) % wFreqs.length];

            // get all pitches from the queue that have frequency === rFreq
            const freqPitches = qPitches.filter((p) => {
              const safeFreq = p.frequency ?? MIN_SHUFFLE_FREQUENCY;
              return safeFreq === rFreq;
            });

            const iNext =
              Math.round(Math.random() * 1_000_000) % freqPitches.length;
            return freqPitches[iNext];
          }

          // this algo enters each pitch into a raffle based on the frequency number and picks a random pitch from the raffle
          // e.g. a pitch with frequency 2 will be entered into the raffle 2x, whereas a pitch with frequency 1 will be entered 1x
          // a large number of pitches of a single frequency will tend to dilute the odds of others
          // 97 pitches of frequency 1 and 1 pitch of frequency 3 => the pitch with frequency 3 will only have a 3% or 3/100 (i.e. 97x1 + 1x3) chance of being selected
          // despite it being "high" frequency and everything else is "low"
          const weightedIDs: string[] = [];

          pitchIDs.forEach((id) => {
            const pitch = props.pitches.find((p) => p._id === id);

            if (!pitch) {
              return;
            }

            // undefined => 1
            const safeFreq = pitch.frequency ?? MIN_SHUFFLE_FREQUENCY;

            // higher frequency => more occurrences of the pitch in the list
            for (let i = 0; i < safeFreq; i++) {
              weightedIDs.push(pitch._id);
            }
          });

          const iNext =
            Math.round(Math.random() * 1_000_000) % weightedIDs.length;
          const nextID = weightedIDs[iNext];
          return props.pitches.find((p) => p._id === nextID);
        }

        default: {
          const length = pitchIDs.length;
          if (length === 0) {
            return undefined;
          }

          /** defaults to 0 if nothing was sent (e.g. user hits next/previous on remote before sending any pitch) */
          const current = selectedData as IPitch | undefined;

          const iCurrent = pitchIDs.findIndex((id) => id === current?._id);

          /** adding length allows the function to work in reverse and loop around */
          const iNext = (iCurrent + length + delta) % length;

          const nextID = pitchIDs[iNext];
          return props.pitches.find((p) => p._id === nextID);
        }
      }
    },
    [props.pitches, def, pitchIDs, selectedData]
  );

  useEffect(() => {
    resetMSHash();
  }, [def]);

  const onSuccessSendPitch = () => {
    /** never auto-send the same pitch more than once
     * e.g. if user re-selects the active row */
    console.debug({
      event: `${CONTEXT_NAME}: onSuccessSendPitch clearing autoSendID`,
      autoSendID: autoSendID,
    });

    setAutoSendID(undefined);

    // i.e. fire button is not awaiting resend anymore
    setLastResend(-Date.now());

    // reset the delay after sending, to avoid impacting non-delayed sends in the future
    setAutoSendDelayMS(undefined);
  };

  const state: IQueueContext = {
    resendKey: lastResend,

    autoSendID: autoSendID,
    resetAutoSendID: () => setAutoSendID(undefined),

    fromRemote: fromRemote,
    setFromRemote: setFromRemote,

    def: def,
    ids: pitchIDs,
    changeQueue: (type, options) => {
      setDef(Q_DEFINITIONS.find((m) => m.type === type) ?? REPEAT_ONE);

      const nextQueue = getNextQueue(options);
      setPitchIDs(nextQueue);
    },

    changePitch: async (config) => {
      if (dialogs.length > 0) {
        return;
      }

      const nextPitch = getNext(config.delta);
      if (!nextPitch) {
        return;
      }

      if (config.delay_ms !== undefined && config.delay_ms > 0) {
        setAutoSendDelayMS(config.delay_ms);
      }

      /** make a note to send the pitch when it's next selected */
      setAutoSendID(config.autoSend ? nextPitch._id : undefined);
      setFromRemote(!!config.autoSend);

      await setPitch(nextPitch, {
        resetPlate: ResetPlateMode.PitchTraj,
        sendConfig: config.autoSend
          ? {
              training: false,
              skipFiringCheck: true,
              trigger: `${CONTEXT_NAME} > change active pitch`,
              usingShots: await fetchShotsForBP(nextPitch, true),
              onSuccess: onSuccessSendPitch,
            }
          : undefined,
      });

      // retrigger select logic
      const c = lookupCoordinates('_id', nextPitch._id);
      setSelected({ ...c });
    },

    sendSelected: (trigger, auto) => {
      /** adds extra metadata to target before sending to machine */
      if (dialogs.length > 0) {
        console.debug(`${CONTEXT_NAME}: open dialogs => skipping S2M`, dialogs);
        return;
      }

      if (auto && !checkActive(true)) {
        // silently skip auto-sending when not connected
        console.debug(
          `${CONTEXT_NAME}: auto-firing while inactive on machine => skipping S2M`
        );
        return;
      }

      if (!pitch) {
        console.debug(`${CONTEXT_NAME}: no pitch => skipping S2M`);
        return;
      }

      if (auto && (!autoSendID || autoSendID !== pitch._id)) {
        // skip auto-sending when not necessary
        console.debug({
          event: `${CONTEXT_NAME}: auto-firing w/ pitch mismatch => skipping S2M`,
          autoSendID: autoSendID,
          pitchID: pitch._id,
        });
        return;
      }

      const getQueuePitchByIndex = (
        index: number,
        delta: number
      ): IPitch | undefined => {
        if (!fromRemote) {
          // only show previous/next when changing from remote
          return;
        }

        if (def.type === QueueType.ShuffleEach) {
          // shuffle should never indicate what comes next/previous because it's random
          return;
        }

        const modulus = pitchIDs.length;
        const safeIndex = (index + delta + modulus) % modulus;
        const id = pitchIDs[safeIndex];
        return props.pitches.find((p) => p._id === id);
      };

      const index = pitchIDs.findIndex((id) => id === pitch._id);

      sendPitchPreview({
        trigger: CONTEXT_NAME,
        current: pitch,
        prev: getQueuePitchByIndex(index, -1),
        next: getQueuePitchByIndex(index, 1),
      });

      setFromRemote(false);

      // i.e. fire button will start awaiting resend
      setLastResend(Date.now());

      const sendCallback = async () => {
        setPitch(pitch, {
          sendConfig: {
            usingShots: await fetchShotsForBP(pitch, true),
            training: false,
            skipPreview: true,
            trigger: trigger,
            list: lists.find((l) => l._id === pitch._parent_id),
            hitter: aHitter,
            onSuccess: onSuccessSendPitch,
          },
        });
      };

      if (autoSendDelayMS === undefined || autoSendDelayMS <= 0) {
        sendCallback();
        return;
      }

      clearTimeout(sendTimeout.current);
      sendTimeout.current = setTimeout(sendCallback, autoSendDelayMS);
    },
  };

  return (
    <QueueContext.Provider value={state}>
      {props.children}
    </QueueContext.Provider>
  );
};
