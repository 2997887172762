import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex, Grid } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { GameDataContext } from 'contexts/game-data.context';
import { t } from 'i18next';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ReactNode, useContext, useMemo } from 'react';

const COMPONENT_NAME = 'GameDataPlayersFilters';

export const GameDataPlayersFilters = (props: {
  onResetFilters?: () => void;
  children?: ReactNode;
}) => {
  const {
    loading,
    seasons,
    seasonGames,
    filterGlobal,
    mergeFilterGlobal,
    filterPlayers,
    mergeFilterPlayers,
  } = useContext(GameDataContext);

  const seasonKey = useMemo(() => Date.now(), [seasons]);

  // options per teams, unique per team name
  const teamOptions = useMemo(() => {
    const teamsDict: { [teamName: string]: IOption } = {};

    seasonGames.forEach((g) => {
      if (!teamsDict[g.home.name]) {
        teamsDict[g.home.name] = {
          label: g.home.name,
          value: g.home.teamPk.toString(),
        };
      }

      if (!teamsDict[g.away.name]) {
        teamsDict[g.away.name] = {
          label: g.away.name,
          value: g.away.teamPk.toString(),
        };
      }
    });

    const output = Object.values(teamsDict);
    output.sort((a, b) => a.label.localeCompare(b.label));
    return output;
  }, [seasonGames]);

  const teamKey = useMemo(() => Date.now(), [teamOptions]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Grid flexGrow="1" columns="5" gap={RADIX.FLEX.GAP.SM}>
          <CommonSelectInput
            key={`sportId-${seasonKey}`}
            id="mlb-stats-sportId"
            name="sportId"
            placeholder="common.level"
            options={[
              { label: 'MLB', value: MlbSportId.MLB.toString() },
              { label: 'MiLB', value: MlbSportId.MiLB.toString() },
            ]}
            value={filterGlobal.sportId.toString()}
            onNumericChange={(v) => {
              if (v === filterGlobal.sportId) {
                return;
              }

              mergeFilterGlobal({
                sportId: v,
              });
            }}
            disabled={loading}
            skipSort
          />

          <CommonSelectInput
            key={`season-${seasonKey}`}
            id="mlb-stats-season"
            name="season"
            placeholder="common.season"
            options={seasons}
            value={filterGlobal.season?.toString()}
            onNumericChange={(v) => {
              if (v === filterGlobal.season) {
                return;
              }

              mergeFilterGlobal({
                season: v,
              });
            }}
            disabled={loading}
            optional
            skipSort
          />

          {props.children}

          <CommonSearchInput
            key={teamKey}
            id="mlb-stats-team"
            name="teamPk"
            title={
              !filterGlobal.season
                ? 'Select a season first.'
                : 'Filter players by team.'
            }
            placeholder="common.current-team"
            options={teamOptions}
            values={
              filterPlayers.teamPk ? [filterPlayers.teamPk.toString()] : []
            }
            onChange={(v) => {
              const teamPk = v.length > 0 ? parseInt(v[0]) : undefined;

              if (teamPk === filterPlayers.teamPk) {
                return;
              }

              mergeFilterPlayers({
                teamPk: teamPk,
              });
            }}
            disabled={loading || !filterGlobal.season}
            optional
          />
        </Grid>

        <Box>
          <Button
            onClick={() => {
              mergeFilterPlayers({
                teamPk: undefined,
                name: undefined,
                hand: undefined,
                side: undefined,
              });
              props.onResetFilters?.();
            }}
            color={RADIX.COLOR.SECONDARY}
            variant={RADIX.BUTTON.VARIANT.SECONDARY}
          >
            <ResetIcon /> {t('common.reset')}
          </Button>
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
